import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './BotUsersStyles.css'
import Swal from 'sweetalert2';
import { deleteBotUser, patchUser } from '../../../API/API';
import { FiX } from 'react-icons/fi';
import { BiPencil, BiUserCircle } from 'react-icons/bi';

export const UserCard = ({ getAllUsers, user }) => {
    const [showConfig, setShowConfig] = useState(false)
    const [userForm, setUserForm] = useState({})

    const toggleShowConfig = () => {
        setUserForm({})
        setShowConfig(!showConfig)
    }

    const handleDeleteUser = (record) => {

        Swal.fire({
            text: `Estás seguro que deseas eliminar el usuario ${user.name}?`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Si, eliminar',
            showConfirmButton: true,
            showCancelButton: true,

        }).then(async (e) => {
            if (e.isConfirmed) {
                await deleteBotUser(user.id);
                getAllUsers()
                setShowConfig(false)
            }
        })
    }
    const handlePatch = async () => {
        await patchUser({ userId: user.id, userName: userForm.userName, newPassword: userForm.password })
        setShowConfig(false)
        getAllUsers();
    }

    const handleInputValues = (e) => {
        setUserForm({
            ...userForm,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (user) {
            setUserForm({
                ...userForm,
                userName: user.name, password: user.password
            })
        }
    }, [user, getAllUsers, showConfig])

    return (
        <>
            <Card className='hoverCard position-relative pb-2 mx-2 mb-3 rounded-md' style={{ width: '14rem' }}>
                <div onClick={toggleShowConfig} className='position-absolute textHoverPrimary' style={{ top: '10px', right: '10px' }}>
                    {showConfig ?
                        <FiX size={20}></FiX>
                        :
                        ''}
                </div>
                {!showConfig && <div className="text-center mt-3">
                    <BiUserCircle className="text-green" size={40}></BiUserCircle>
                </div>}
                {showConfig ?
                    <div className="py-3">
                        <div className='text-center mx-3'>
                            <div style={{ width: "99%" }}>
                                <small className='d-flex justify-content-start'>Nombre</small>
                                <input onChange={handleInputValues} value={userForm.userName} name='userName' className='form-control mb-1 customInputUserCards mt-1' ></input>
                                <small className='d-flex justify-content-start'>Contraseña</small>
                                <input onChange={handleInputValues} value={userForm.password} name='password' className='form-control mb-1 customInputUserCards mt-1'></input>
                            </div>
                        </div>
                        <div>
                            <button className='btn btn-customgray mt-2 mx-1' onClick={handleDeleteUser}>Eliminar</button>
                            <button className='btn btn-customgray  mt-2 mx-1' onClick={handlePatch}>Guardar</button>
                        </div>
                    </div>
                    :
                    <Card.Body>
                        <Card.Title>{user.name}</Card.Title>
                    </Card.Body>
                }
            </Card>
            <div role="button" className="cursor-pointer" onClick={toggleShowConfig}>
                <BiPencil className="rounded-circle p-2 bg-dark inline-block" size={30}></BiPencil>
            </div>
        </>
    );
};
