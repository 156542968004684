import React, { useState } from 'react';
import { Card } from 'react-bootstrap'; // Importa el componente Card de Bootstrap
import { createNewUser } from '../../../API/API';

export const CreateUserCard = ({ getAllUsers }) => {
    const [newUserForm, setNewUserForm] = useState({ name: '', password: '' })

    const handleCreate = async () => {
        const payload = {
            name: newUserForm?.name?.toLowerCase(),
            password: newUserForm.password,
        }
        
        await createNewUser(payload)
        setNewUserForm({ name: '', password: '' })
        getAllUsers()
    }

    const handleInputValue = (e) => {
        setNewUserForm({
            ...newUserForm,
            [e.target.name]: e.target.value
        })
    }

    return (
        <>
            <Card className='hoverCard_create position-relative pb-2 mx-2 mb-3 rounded-md' style={{ width: '12rem' }}>
                <>
                    <div className='d-flex justify-content-center p-1 py-4'>
                        <div style={{ width: '99%' }}>
                            <div className="">
                                <input onChange={handleInputValue} value={newUserForm.name} name='name' className='form-control customInputUserCards  py-2 mb-1' placeholder='Nombre'></input>
                                <input onChange={handleInputValue} value={newUserForm.password} name='password' type='password' className='form-control customInputUserCards  mb-1 py-2' placeholder='Contraseña'></input>
                            </div>
                        </div>
                    </div>
                </>
            </Card>
            <div>
                <button className='btn btn-green-outline text-white mx-1 px-3' onClick={handleCreate}>Crear</button>
            </div>
        </>
    );
};
