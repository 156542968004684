export const formatUTCtoArgentinaTime = (dateString) => {
    // Convert the ISO date string to a Date object
    const date = new Date(dateString);
    // Restar las horas especificadas
    date.setHours(date.getHours() - 3);
    // Formatear el resultado como "dd-mm-yyyy hh:mm:ss"
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}