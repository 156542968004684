import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { editPaymentScale, getPaymentScale } from '../API';
import Swal from 'sweetalert2';
import { MdInfoOutline } from 'react-icons/md';

export const PaymentScaleModal = ({ showPaymentScaleModal, setShowPaymentScaleModal }) => {

    const [content, setContent] = useState('');

    const getInfo = async () => {
        let test = await getPaymentScale();
        setContent(test)
    }

    useEffect(() => {
        getInfo()
    }, [])

    const handleSubmit = async () => {
        await editPaymentScale(content)
            .then(() =>
                setShowPaymentScaleModal(false)
            )
            .catch(() =>
                Swal.fire({ icon: 'error', text: 'Ha habido un error al actualizar, reinicia e intenta de nuevo' })
            )

    }

    return (
        <Modal size='lg' onHide={() => setShowPaymentScaleModal(false)} show={showPaymentScaleModal}>
            <Modal.Header>
                <Modal.Title>
                    Escala de pagos
                    <br />
                    <small className="text-secondary" style={{ fontSize: '12px' }}>Configura aquí la escala de pago según consideres apropiada.
                        <br />
                    </small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center">
                    <MdInfoOutline size={28} className="text-warning" />
                    <span className="mx-2" style={{ fontSize: '15px' }}>
                        En caso de dejar vacio este campo, el botón "Escala de pago" no será mostrado a los usuarios en el panel de carga.
                    </span>
                </div>
                <div className="my-5">
                    <textarea className="form-control customDarkInput" value={content} onChange={(e) => setContent(e.target.value)} style={{ minHeight: '500PX' }}></textarea>
                </div>
                <div className="d-flex justify-content-between">
                    <div>
                        <button className='btn btn-dark' onClick={() => setContent('')}>Limpiar</button>
                    </div>
                    <div className="d-flex gap-2 justify-content-end">
                        <button className='btn btn-green-outline mx-2' onClick={() => setShowPaymentScaleModal(false)}>Cancelar</button>
                        <button className='btn btn-green' onClick={handleSubmit}>Guardar</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
