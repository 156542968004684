import React, { useEffect, useState } from 'react'
import { AiTwotoneWarning } from 'react-icons/ai';
import { BiArrowBack, BiArrowFromLeft, BiArrowToLeft } from 'react-icons/bi';
import { BsArrowUpRightCircle } from 'react-icons/bs';

export const NotificationsModal = ({ notifications, setShowNotificationModal }) => {

    const [showSingleNotification, setShowSingleNotification] = useState(false)
    const [singleNotificationInfo, setSingleNotificationInfo] = useState({})

    const handleShowSingle = (e) => {
        setShowSingleNotification(true)
        setSingleNotificationInfo(e)
    }

    return (
        <div className="my-modal-notifications pb-4" style={{ zIndex: '999999999', maxWidth: '600px' }}>
            <div className="modal-dialo rounded pt-3">
                <div className=" ">
                    <div className="modal-">
                        <p><AiTwotoneWarning size={30}></AiTwotoneWarning></p>
                    </div>
                    {
                        !showSingleNotification ?
                            <>
                                <div className='text-white'>
                                    {notifications.length > 0 &&
                                        <>
                                            {notifications[0].title}
                                            <br></br>
                                            <span className='text-secondary'>  {notifications[0].description}</span>
                                        </>
                                    }

                                </div>
                                <div className='d-flex w-100 justify-content-center'>
                                    <div className='w-100 p-5'>
                                        {notifications.length > 1 && <small className='text-white'>Otras notificaciones:</small>}

                                        {notifications.length > 1 ? notifications.slice(1, notifications.length).map(e =>

                                            <div className='textHoverPrimary'>
                                                <div onClick={() => handleShowSingle(e)} className=' pointer d-flex justify-content-between border-secondary border rounded my-2 px-2 py-1 '>
                                                    <span className='textHoverPrimary'>{e.description}</span>
                                                    <div>
                                                        <BsArrowUpRightCircle size={16}></BsArrowUpRightCircle>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            :
                                            <>
                                                <div className='mt-5' >
                                                    No tienes notificaciones.
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='text-white'>
                                    {notifications.length > 0 &&
                                        <>
                                            <div className='w-100 d-flex justify-start mx-2 pointer textHoverPrimary'>
                                                <BiArrowBack size={22} onClick={() => setShowSingleNotification(false)}></BiArrowBack>
                                            </div>
                                            {singleNotificationInfo.title}
                                            <br></br>
                                            <span className='text-secondary'>  {singleNotificationInfo.description}</span>
                                        </>
                                    }

                                </div>

                            </>
                    }
                    <div className="p-3 position-absolute" style={{ bottom: '0', right: '0' }}>
                        <button type="button" className="btn btn-secondary" onClick={() => setShowNotificationModal(false)} >Close</button>
                    </div>
                </div>
            </div>
        </div >
    )
}
