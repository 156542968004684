import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios'
import { createRoot } from 'react-dom/client';

// axios.defaults.baseURL = process.env.REACT_APP_API || "http://localhost:3001"
axios.defaults.baseURL = process.env.REACT_APP_API || "https://api22.onrender.com"

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,);

reportWebVitals();
