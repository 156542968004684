import axios from 'axios'
import { getApiConfig } from '../../API/getConfig';

// const adminName = process.env.REACT_APP_CAJERO
const adminName = localStorage.getItem('ownerName');

export const fetchButtons = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/getButtons`, config)
            .then((res) => {
                result = res.data || []
            })
    } catch (error) {
        console.log(error)
    }

    return result
}
export const fetchAdminPublicInfo = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/getAdminPublicInfo`, config)
            .then((res) => {
                result = res?.data ?? {}
            })
    } catch (error) {
        console.log(error)
    }

    return result
}
