import React, { useEffect, useState } from 'react'
import { Router, useParams } from 'react-router';
import { createVariant, deleteButton, editMessage, fetchButton, fetchButtonAdmin, testAdminToken } from '../API';
import { Card, Modal } from 'react-bootstrap';
import './ButtonCustomization.css'
import { MessageCard } from '../MessageCard/MessageCard';
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { TbGift, TbRosetteNumber1 } from 'react-icons/tb'
import { GrSubtractCircle } from 'react-icons/gr'
import { FiRefreshCcw, FiUnlock, FiUsers } from 'react-icons/fi'
import { MdOutlineHistoryToggleOff } from 'react-icons/md'

export const ButtonCustomization = () => {

    //get param ":id" from url

    const params = useParams();
    const [buttonId, setButtonId] = useState('')
    const [buttonDetail, setButtonDetail] = useState([])
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [newMessage, setNewMessage] = useState({ text: '' })
    const [loading, setLoading] = useState(true);
    const [adminInfo, setAdminInfo] = useState({})

    const adminName = process.env.REACT_APP_CAJERO

    useEffect(() => {
        setButtonId(params.id)
        fetchButtonDetail();
        testToken();
    }, [])

    const testToken = async () => {
        let test = await testAdminToken();
        if (test?.data?.status) {
            setAdminInfo(test.data.user)
        }
    }

    const fetchButtonDetail = async () => {
        await fetchButtonAdmin(params.id).then(res => {
            setButtonDetail(res)
            setLoading(false);
        })
    }

    const createNewMessage = () => {
        createVariant({ text: newMessage.text, buttonId: buttonId }).then(res => fetchButtonDetail())
        setShowCreateModal(false)
    }

    const handleTextArea = (event) => {
        setNewMessage({ ...newMessage, text: event.target.value })
    }

    const handleDeleteButton = async () => {
        await deleteButton(buttonId).then(res => console.log(res))
        window.location.href = '/admin'
    }

    const iconsHandler = (button) => {
        if (button == "static_reset") { return <FiRefreshCcw size={30}></FiRefreshCcw> }
        if (button == "static_unblock") { return <FiUnlock size={30}></FiUnlock> }
        if (button == "static_bonus") { return <TbGift size={30} /> }
        if (button == "static_first") { return <TbRosetteNumber1 size={30}></TbRosetteNumber1> }
        if (button == "static_clone") { return <FiUsers size={30}></FiUsers> }
        if (button == "static_history") { return <MdOutlineHistoryToggleOff size={30}></MdOutlineHistoryToggleOff> }
        if (button == "static_subtract") { return <GrSubtractCircle size={30}></GrSubtractCircle > }
        if (button == "static_add") { return <AiOutlinePlusCircle size={30}></AiOutlinePlusCircle> }
    }

    return (
        <>
            <Modal size='lg' onHide={() => setShowCreateModal(false)} show={showCreateModal}>
                <Modal.Header>
                    <Modal.Title>Crear variante para boton {buttonDetail.name} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea onChange={handleTextArea} className='form-control mt-2 customDarkInput' placeholder='Mensaje' style={{ minHeight: '250px' }}></textarea>
                    <hr></hr>
                    <small>
                        <small className='text-info'><b>Para agregar una variable, escribela entre doble guion bajo. Ejemplo: __CBU__</b></small>

                        {(buttonDetail.category !== "static_button") &&
                            <p>
                                Variables disponibles:

                                <ul className='list-unstyled mt-2'>


                                    <>
                                        <li>
                                            <b>__CBU__</b> // CBU del día.
                                        </li>
                                        <li>
                                            <b>__TITULAR__</b> // Titular del CBU del día.
                                        </li>
                                        <li>
                                            <b>__ALIAS__</b> // Alias del CBU del día.
                                        </li>
                                        <li>
                                            <b>__HORA__</b> // Hora límite.
                                        </li>
                                        {/* <li>
                                            <b>__LINK__</b> // Link de la página.
                                        </li> */}
                                    </>

                                </ul>
                            </p>
                        }
                        <div className='mt-3'></div>
                        <small className='text-black my-4'>
                            <ul>
                                {(buttonDetail.name === "static_bonus") && (
                                    <>
                                        <b>__CANTIDAD__</b> - CANTIDAD TOTAL DE FICHAS CARGADAS.<br />
                                        <b>__USUARIO__</b> - USUARIO CARGADO<br />
                                        <b>__BONUS__</b> - BONIFICACIÓN TOTAL. SOLO NÚMERO, SIN %. Ejemplo: 20<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_clone") && (
                                    <>
                                        <b>__NEWNAME__</b> - NUEVO NOMBRE DE USUARIO.<br />
                                        <b>__USUARIO__</b> - NOMBRE ANTERIOR<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                        <b>__PASSWORD__</b> -  {adminInfo?.default_users_password ?? ''}<br />
                                        <span className="text-secondary">
                                            <span><span className="text-danger">*</span> Puedes configurar la contraseña por defecto para usuarios en la sección CUENTA {'>'} Configuración del BOT.</span>
                                        </span>
                                    </>
                                )}

                                {(buttonDetail.name === "static_reset") && (
                                    <>
                                        <b>__USUARIO__</b> - NOMBRE DE USUARIO<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                        <b>__PASSWORD__</b> -  {adminInfo?.default_users_password ?? ''}<br />
                                        <span className="text-secondary">
                                            <span><span className="text-danger">*</span> Puedes configurar la contraseña por defecto para usuarios en la sección CUENTA {'>'} Configuración del BOT.</span>
                                        </span>
                                    </>
                                )}

                                {(buttonDetail.name === "static_unblock") && (
                                    <>
                                        <b>__USUARIO__</b> - NOMBRE DE USUARIO<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_subtract") && (
                                    <>
                                        <b>__CANTIDAD__</b> - CANTIDAD TOTAL DE FICHAS BAJADAS.<br />
                                        <b>__USUARIO__</b> - USUARIO CARGADO<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_add") && (
                                    <>
                                        <b>__CANTIDAD__</b> - CANTIDAD TOTAL DE FICHAS CARGADAS.<br />
                                        <b>__USUARIO__</b> - USUARIO CARGADO<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_newuser") && (
                                    <>
                                        <b>__USUARIO__</b> - USUARIO CREADO <br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                        <b>__PASSWORD__</b> -  {adminInfo?.default_users_password ?? ''}<br />
                                        <span className="text-secondary">
                                            <span><span className="text-danger">*</span> Puedes configurar la contraseña por defecto para usuarios en la sección CUENTA {'>'} Configuración del BOT.</span>
                                        </span>
                                    </>
                                )}
                            </ul>

                        </small>

                    </small>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-green-outline' onClick={() => setShowCreateModal(false)}>Cancelar</button>
                    <button className='btn btn-success' onClick={createNewMessage}>Crear</button>
                </Modal.Footer>
            </Modal>

            <div className='DashboardContainer'>
                <div className="ContainerGral">
                    <div className='m-3'>
                        <button
                            className="btn btn-outline-secondary border-0 my-0 py-1 m-1"
                            type="submit"
                            onClick={() => console.log(buttonDetail)}
                        >{buttonDetail.category === 'static_button' ? iconsHandler(buttonDetail.name) : buttonDetail.name}
                        </button>
                    </div>

                    <div className='d-flex flex-wrap justify-content-center'>
                        {buttonDetail?.Messages &&
                            buttonDetail.Messages.map((mess, index) => (
                                <MessageCard key={index} message={mess} fetchButtonDetail={fetchButtonDetail}></MessageCard>
                            ))}
                    </div>

                    <div className='mt-5'>
                        {!loading && buttonDetail.category !== "static_button" && <button className='btn btn-green-outline text-danger border-danger mx-3' onClick={handleDeleteButton}>Eliminar botón</button>}
                        <button className='btn btn-success mx-3' onClick={() => setShowCreateModal(true)}>Agregar variante</button>
                    </div>
                    <div class='mt-5'>

                        <small className='text-white'>
                            <h5 className='my-3'>Variables disponibles para este botón:</h5>
                            <ul>
                                {(buttonDetail.name === "static_bonus") && (
                                    <>
                                        <b>__CANTIDAD__</b> - CANTIDAD TOTAL DE FICHAS CARGADAS.<br />
                                        <b>__USUARIO__</b> - USUARIO CARGADO<br />
                                        <b>__BONUS__</b> - BONIFICACIÓN TOTAL. SOLO NÚMERO, SIN %. Ejemplo: 20<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_clone") && (
                                    <>
                                        <b>__NEWNAME__</b> - NUEVO NOMBRE DE USUARIO.<br />
                                        <b>__USUARIO__</b> - NOMBRE ANTERIOR<br />
                                        <b>__PASSWORD__</b> -   CONTRASEÑA CONFIGURADA POR DEFECTO PARA NUEVOS USUARIOS<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_reset") && (
                                    <>
                                        <b>__USUARIO__</b> - NOMBRE DE USUARIO<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                        <b>__PASSWORD__</b> -  {adminInfo?.default_users_password ?? ''}<br />
                                        <span className="text-secondary">
                                            <span><span className="text-danger">*</span> Puedes configurar la contraseña por defecto para usuarios en la sección CUENTA {'>'} Configuración del BOT.</span>
                                        </span>
                                    </>
                                )}

                                {(buttonDetail.name === "static_unblock") && (
                                    <>
                                        <b>__USUARIO__</b> - NOMBRE DE USUARIO<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_subtract") && (
                                    <>
                                        <b>__CANTIDAD__</b> - CANTIDAD TOTAL DE FICHAS BAJADAS.<br />
                                        <b>__USUARIO__</b> - USUARIO AL QUE SE LE BAJARON FICHAS<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_add") && (
                                    <>
                                        <b>__CANTIDAD__</b> - CANTIDAD TOTAL DE FICHAS CARGADAS.<br />
                                        <b>__USUARIO__</b> - USUARIO CARGADO<br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                    </>
                                )}

                                {(buttonDetail.name === "static_newuser") && (
                                    <>
                                        <b>__USUARIO__</b> - USUARIO CREADO <br />
                                        <b>__LINK__</b> - LINK DE LA PÁGINA<br />
                                        <b>__PASSWORD__</b> -  {adminInfo?.default_users_password ?? ''} <span className="text-danger">*</span><br />
                                        <br />
                                        <span className="text-secondary">
                                            <span><span className="text-danger">*</span> Puedes configurar la contraseña por defecto para usuarios en la sección CUENTA {'>'} Configuración del BOT.</span>
                                        </span>
                                    </>
                                )}

                            </ul>

                            {(buttonDetail.category !== "static_button") &&
                                <p>
                                    <ul className='list-unstyled mt-2'>


                                        <>
                                            <li>
                                                <b>__CBU__</b> // CBU del día.
                                            </li>
                                            <li>
                                                <b>__TITULAR__</b> // Titular del CBU del día.
                                            </li>
                                            <li>
                                                <b>__ALIAS__</b> // Alias del CBU del día.
                                            </li>
                                            <li>
                                                <b>__HORA__</b> // Hora límite.
                                            </li>
                                            {/* <li>
                                                <b>__LINK__</b> // Link de la página.
                                            </li> */}
                                        </>

                                    </ul>
                                </p>
                            }
                        </small>


                    </div>
                </div>
            </div>
        </>
    )
}
