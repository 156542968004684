import React, { useEffect, useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { getOwnerInfo } from '../API';
import './Settings.css'
import { BotConfig } from './BotConfig/BotConfig';
import { PanelConfig } from './PanelConfig/PanelConfig';
import { AiFillLayout } from "react-icons/ai";
import { RiUserSettingsLine } from 'react-icons/ri';

export const Settings = () => {
    // State para almacenar los valores de los campos
    const [adminData, setAdminData] = useState({})
    const [tab, setTab] = useState(1)
    const [formData, setFormData] = useState({
        newName: '',
        nickname: '',
        password: '',
        newPassword: '',
        email: '',
        firstLetter: '',
        panel_password: '',
        newPanelPassword: '',
        panel_password_repeat: '',
        password_repeat: '',
    });

    // Función para manejar cambios en los campos del formulario
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Función para enviar el formulario
    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes agregar la lógica para enviar los datos al servidor
    };

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        let result = await getOwnerInfo();
        if (result?.data) {
            setAdminData(result.data)
            setFormData(result.data)
        }
    }


    return (
        <div className='d-flex flex-wrap justify-content-center px-5 m-auto'>
            <div className="mt-5 responsivecard px-3" style={{ minWidth: '900px' }}>
                <div className='py-2 d-flex justify-content-around'>
                    <div className="d-flex gap-4 justify-content-around">
                        <div role='button' className={`py-1 mx-2 px-5 rounded-md cursor-pointer d-flex align-items-center ${tab === 1 ? 'selectedTabLeft' : 'settings-tab'}`} onClick={() => setTab(1)}>
                            <RiUserSettingsLine></RiUserSettingsLine>
                            <span className='mx-2'>Configuración del BOT</span>
                        </div>
                        <div role='button' className={`py-1 mx-2 px-5 rounded-md cursor-pointer d-flex align-items-center ${tab === 2 ? 'selectedTabRight' : 'settings-tab'}`} onClick={() => setTab(2)}>
                            <AiFillLayout></AiFillLayout>
                            <span className='mx-2'>Configuración del PANEL</span>
                        </div>
                    </div>
                </div>
                <div className='py-2'>
                    {tab === 1 && <BotConfig adminData={adminData}></BotConfig>}
                    {tab === 2 && <PanelConfig adminData={adminData}></PanelConfig>}
                </div>

            </div>
        </div>
    );
};

export default Settings;
