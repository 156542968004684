import React from 'react'
import { deleteNotification } from '../../../API/API'
import { AiOutlineCloseSquare } from 'react-icons/ai'

export const NotificationCard = ({ getAllNotifications, notification, setSelectedNotification, selectedNotification }) => {

    const deleteCall = async () => {
        await deleteNotification(notification.id)
        getAllNotifications()
    }

    return (
        <div className='d-flex w-100 align-items-start gap-2 my-3 position-relative'>

            <div className='w-100 bg-black'>
                <div className={`textHoverPrimary w-100`}>
                    <div className='pointer d-flex justify-content-between rounded px-3 py-2'>
                        <span style={{ fontSize: '18px' }}>{notification.title}</span>
                    </div>

                </div>
                <div className=' my-4 d-flex w-100 justify-content-start px-3'>
                    <span
                        style={{ fontSize: '14px' }}
                        className='d-flex w-100 text-start'>
                        {notification.description}
                    </span>
                </div>
            </div>

            <div className='mx-2 pointer text-green align-items-start d-flex my-1 position-absolute' style={{ cursor: 'pointer', right: '5px', top: '10px' }} onClick={deleteCall}>
                <AiOutlineCloseSquare size={25}></AiOutlineCloseSquare >
            </div>
        </div>
    )
}
