import React, { useEffect, useState } from 'react'
import { NotificationCard } from './NotificationCard'
import { createNotification } from '../../../API/API'
import { getNotificationsAdmin } from '../API'
import { BiBell } from 'react-icons/bi'

export const NotificationsPanel = () => {

    const [notifications, setNotifications] = useState([])
    const [selectedNotification, setSelectedNotification] = useState('')
    const [createMode, setCreateMode] = useState(false)
    const [createNotificationForm, setCreateNotificationForm] = useState({
        title: '',
        description: ''
    })

    useEffect(() => {
        getAllNotifications()
    }, [])


    const getAllNotifications = async () => {
        const response = await getNotificationsAdmin()
        const newNotifications = response;
        if (response) {
            setNotifications(newNotifications)
        }
    }

    const handleinputsChange = (e) => {
        setCreateNotificationForm({
            ...createNotificationForm,
            [e.target.name]: e.target.value
        })
    }

    const publishNotification = async () => {
        await createNotification(createNotificationForm)
        setCreateMode(false)
        setCreateNotificationForm({
            title: '',
            description: ''
        })
        getAllNotifications()
    }

    return (
        <div className=''>
            {!createMode &&
                <>
                    {notifications?.map(e =>
                        <>
                            <NotificationCard getAllNotifications={getAllNotifications} notification={e} selectedNotification={selectedNotification} setSelectedNotification={setSelectedNotification}></NotificationCard>
                        </>
                    )}

                    {notifications.length === 0 &&
                        <div>No tienes notificaciones creadas actualmente.</div>}
                    <div className='my-4 d-flex justify-content-end'>
                        <button className='btn btn-green d-flex py-1 align-items-center' onClick={() => setCreateMode(true)}>
                            <div className="mr-2 d-flex align-items-center justify-content-center">
                                <BiBell></BiBell>
                            </div>
                            Nueva notificacion</button>
                    </div>
                </>
            }
            {createMode &&
                <div>
                    <div>
                        <div className="d-flex w-100 mb-3">
                            Titulo
                        </div>
                        <div className='w-100'>
                            <input onChange={handleinputsChange} name='title' value={createNotificationForm.title} className='w-100 form-control form-control customUltradarkInput'></input>
                        </div>
                    </div>
                    <div className="d-flex w-100 mb-3 mt-3">
                        Descripción
                    </div>

                    <div>
                        <textarea onChange={handleinputsChange} name='description' value={createNotificationForm.description} className='mt-2 form-control form-control customUltradarkInput' style={{ minHeight: '250px' }}></textarea>
                    </div>

                    <div className='my-4 d-flex justify-content-between'>
                        <button className='btn btn-customdark text-white' onClick={() => setCreateMode(false)}>Cancelar</button>
                        <button className='btn btn-green' onClick={publishNotification}>Publicar</button>
                    </div>
                </div>
            }

        </div>
    )
}
