import React, { useEffect, useState } from 'react'
import { UserCard } from './UserCard'
import { CreateUserCard } from './CreateUserCard'
import { getBotUsers } from '../API'

export const BotUsersPanel = () => {
    const [loading, setLoading] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    useEffect(() => {
        getAllUsers()
    }, [])

    const getAllUsers = async () => {
        setLoading(true)
        const response = await getBotUsers()
        setLoading(false)
        const usersData = response;
        if (response) {
            setAllUsers(usersData)
        }
    }

    return (
        <div className='container'>
            <div className="d-flex mb-5">
                Usuarios Existentes
            </div>
            <div className="d-flex justify-content-center">
                {!loading && <div className='row justify-content-center'>

                    {allUsers.length > 0 && allUsers.map(user =>
                        <div className='mt-2'>
                            <UserCard getAllUsers={getAllUsers} user={user}></UserCard>
                        </div>
                    )}
                    <div className=' mt-2'>
                        <CreateUserCard getAllUsers={getAllUsers}></CreateUserCard>
                    </div>
                </div>}
                {loading && <div>Cargando...</div>}
            </div>
        </div>

    )
}
