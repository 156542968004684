import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import './DataHandler.css'
import { getApiConfig } from '../../../API/getConfig'
import { MdModeEdit } from 'react-icons/md'

export const DataHandler = (props) => {


    const URLBASE = 'http://localhost:3001'
    // const adminName = process.env.REACT_APP_CAJERO
    const [adminName, setAdminName] = useState(sessionStorage.getItem('ownerName'));

    // Local states

    const [form, setForm] = useState({
        CBU: '',
        hour_limit: '',
        titular: '',
        alias: ''
    })

    const [newObj, setNewObj] = useState({
        adminName: adminName
    })

    const [editing, setEditing] = useState(false)

    const postData = async (e) => {
        e.preventDefault()
        let config = getApiConfig();
        setEditing(false)
        try {
            await axios.post(
                `/editCBU`, newObj, config
            ).then((result) => {
                console.log('hecho!')
                setForm({ CBU: newObj.CBU, hour_limit: newObj.hour_limit, titular: newObj.titular })
            })
        } catch (error) {
            console.log(error)
        }

        props.getData()
    }

    const handleEditing = (e) => {

        e.preventDefault()
        setEditing(!editing)
    }

    const handleNewObj = (e) => {
        setNewObj({
            ...newObj,
            [e.target.name]: e.target.value
        })
    }

    const refreshData = () => {
        props.getData()
    }

    const cleanForm = async (e) => {
        e.preventDefault()
        let config = getApiConfig();

        setEditing(false)
        try {
            await axios.post(
                `/editCBU`, { CBU: '', hour_limit: '', titular: '', alias: '' }, config
            ).then((result) => {
                console.log('hecho!')
                setForm({ CBU: '', hour_limit: '', titular: '', alias: '' })
                setNewObj({ CBU: '', hour_limit: '', titular: '', alias: '' })
            })
        } catch (error) {
            console.log(error)
        }

        props.getData()
    }

    return (
        // <div className='p-3 darkBackground'>
        <Col className="darkBackground mb-4 py-1" style={{ minWidth: '520px' }}>

            {
                editing ?
                    <>
                        <div>CBU:
                            <input name='CBU' onChange={handleNewObj} className='form-control my-1 w-50 m-auto form-control form-control customDarkInput text-white border-secondary'
                                placeholder={props.fetchedData?.CBU ? props.fetchedData.CBU : ''}>
                            </input>
                        </div>
                        <div>Titular:
                            <input name='titular' onChange={handleNewObj} className='form-control my-1 w-50 m-auto form-control form-control customDarkInput text-white border-secondary' placeholder={props.fetchedData?.titular ? props.fetchedData.titular : ''}>
                            </input>
                        </div>

                        <div>Alias: {`(opcional)`}
                            <input name='alias' onChange={handleNewObj} className='form-control my-1 w-50 m-auto form-control form-control customDarkInput text-white border-secondary' placeholder={props.fetchedData?.alias ? props.fetchedData.alias : ''}>
                            </input>
                        </div>

                        <div>Hasta las:
                            <input name='hour_limit' onChange={handleNewObj} className='form-control my-1 w-50 m-auto form-control form-control customDarkInput text-white border-secondary' placeholder={props.fetchedData?.hour_limit ? props.fetchedData.hour_limit : ''}>
                            </input>
                        </div>


                        <form onSubmit={postData}>

                            <button type='submit' className='btn btn-green btn-sm mt-2 mx-2'>Guardar</button>

                            <button type='button' onClick={cleanForm} className='btn btn-outline-secondary btn-sm mt-2 mx-2'>Limpiar datos</button>

                        </form>
                    </>
                    :

                    <div className='m-auto d-flex flex-column position-relative' style={{ fontSize: '15px', minWidth: '520px' }}>
                        <div>
                            <span className="mx-2">{props.fetchedData?.CBU ? props.fetchedData.CBU : ''}</span>
                        </div>
                        <div className="d-flex justify-content-center mt-1">
                            <span className="mx-2">{props.fetchedData?.titular ? props.fetchedData.titular : ''}</span>

                            <span className="mx-2">{props.fetchedData?.alias ? props.fetchedData.alias : 'sin alias'}</span>

                            <span className="mx-2">{props.fetchedData?.hour_limit ? 'Hasta las ' + props.fetchedData.hour_limit + 'hs' : ''}</span>
                        </div>
                        <div className='editbutton position-absolute' style={{ top: '5px', right: '5px' }} role="button" onClick={handleEditing}>
                            <MdModeEdit size={22}></MdModeEdit>
                        </div>
                    </div>

            }

        </Col>
    )
}
