import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import './MessageCard.css'
import { deleteVariant, editMessage } from '../API'
export const MessageCard = ({ message, index, fetchButtonDetail }) => {

    const [text, setText] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const handleHideModal = () => {
        setShowModal(false)
    }

    useEffect(() => {
        setText(message.text)
    }, [message])

    const handleEditButton = () => {
        editMessage(message.id, text).then(() => fetchButtonDetail().then(res => console.log(res)))
        setShowModal(false)
    }

    const handleDeleteMessage = () => {
        deleteVariant(message.id).then(() => fetchButtonDetail().then(res => console.log(res)))
        setShowModal(false)
    }

    return (
        <>
            {showModal &&
                <Modal size='lg' animation={true} show={showModal} onHide={handleHideModal} backdropClassName="custom-backdrop" >
                    <form>
                        <div className="form-group">
                            <label htmlFor="message">Mensaje</label>
                            <textarea
                                type="text"
                                className="form-control customDarkInput"
                                id="message"
                                name="message"
                                onChange={(e) => setText(e.target.value)}
                                value={text}
                                style={{ minHeight: '250px' }}
                            />
                        </div>
                    </form>
                    <Modal.Footer>
                        <button className="btn btn-green-outline text-danger border-danger my-0 py-0 m-1" type="submit" onClick={handleDeleteMessage}>
                            Eliminar variante
                        </button>

                        <button className="btn btn-success my-0 py-0 m-1" type="submit" onClick={handleEditButton}>
                            Guardar
                        </button>


                    </Modal.Footer>
                </Modal>
            }

            <div key={index} className='w-25 border p-2 m-2 rounded border-secondary cardcustomb' onClick={() => setShowModal(true)}>
                <div>
                    <small>
                        {message.text}
                    </small>
                </div>
            </div>
        </>
    )
}
