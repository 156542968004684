
const cbu = `1430001713031909460015`
const hour_limit = "18"
const link = `https://jugalodos.com/ `
const cbu_titular = `Camila Mariel Ayala`
const carga_minima = 1000
const premio_minimo = 2000
const last_hour = "23:50"
const first_hour = "10"
const linkReferido = "https://wa.link/mmdkpx"
const linkReferido2 = "https://wa.link/qqbv2o"
const default_password = "hola123"
// ------------ Panel de usuarios

const randomizer = (array) => {
    return array[Math.floor(Math.random() * array.length)]
}


export const createUser_success = (full_user, data) => {
        return `
Paso a dejarte información! ❤️

Carga minima 1000
Retiro minimo 2000
Horario de pagos 10am a 23:50pm

Nombre de usuario: ${full_user}
Tu contraseña es: ${default_password}

Link para ingresar a jugar: Somos _cas.platinium ${link}

Por ser tu primer carga tenes un 10% de bonificacion!
Si queres realizarla consultanos cbu`
}


const cloneRandomizer = ['Por motivos a una', 'Para una', 'Para su', 'Para brindarle una']

export const cloneUser_success = (full_user, data) => {
    return `${randomizer(cloneRandomizer)} mejor atencion y funcionamiento de la plataforma a continuación le vamos a enviar su nuevo nombre de usuario con contraseña *hola123*
Link de ingreso: ${link}
Importante: si tiene fichas en su anterior usuario no hay ningún problema puede utilizarlas y su proxima carga la hacemos en esta nuevo.
Cualquier duda estamos para resolver❤️

*Tu nuevo usuario es:* ${full_user}`
}




const unblockRandomizer = ['Usuario desbloqueado:', 'Desbloqueado!']

export const unblock_success = (user) => {
    return `${randomizer(unblockRandomizer)} ${user}. Intenta entrar de nuevo por favor!`
}




const resetpwRandomizer = ['Cuenta reestablecida!', 'Contraseña reiniciada!', 'Usuario reestablecido!']

export const resetpw_success = (user) => {
    return `
${randomizer(resetpwRandomizer)}
Usuario: ${user} 
Contraseña: hola123
Intenta entrar de nuevo por favor.`
}


// ---------- FICHAS


const fichasRandomizer = ['!!', '!', ' ']

export const fichas_success = (user, quantity) => {
    return `
${quantity} FICHAS CARGADAS A ${user}${randomizer(fichasRandomizer)}
GRACIAS POR CONFIAR🫶🏻🥳🥳🥳
`
}



const fichaspromoRandomizer = ['También tenemos', 'Te cuento que también tenemos', 'Contamos con']
export const fichas_con_promo = (user, quantity, bonificacion) => {
    let quantityy = Number(quantity)
    let bonus = bonificacion ? bonificacion : 10
    let promo = Number(quantityy) * bonus / 100
    let total = Number(quantityy) + Number(promo)

    return `
${total} FICHAS CARGADAS A ${user}!!
GRACIAS POR CONFIAR🫶🏻🥳🥳🥳
${bonificacion == 10 ? "Por ser tu primera vez tenés" : "Tenés"} ${bonus}% de beneficio en esta carga!

Agendame mi nombre es Mora, asi te llegan mis difusiones con bonifaciones 
${randomizer(fichaspromoRandomizer)} un plan de referidos para que ganes fichas GRATIS si tenés amigos/familiares que jueguen, por recomendarme te regalo fichas 🎁 Te dejo mi link de wpp para que se los envíes:
${linkReferido}`
}


// ---------- COPYPASTES

export const fichas_primeravez_success = () => {
    return `FICHAS CARGADAS!!
GRACIAS POR CONFIAR🫶🏻🥳🥳🥳

Agendame mi nombre es Mora asi te llegan mis difusiones con bonifaciones 
${randomizer(fichaspromoRandomizer)} un plan de referidos para que ganes fichas GRATIS si tenés amigos/familiares que jueguen, por recomendarme te regalo fichas 🎁 Te dejo mi link de wpp para que se los envíes:
${linkReferido}`
}


export const bienvenidaConNombre = () => {
    return `👋 *Somos cas.platinium* 
${randomizer(['En este link', 'En esta página', "En el siguiente link"])} encontrarás todos los juegos 🎰
${link}

Te creamos una cuenta gratuita, y te cargamos la cantidad de fichas que desees, a través de transferencia y se acreditan al instante. 

*Carga mínima* -$${carga_minima}
*Retiró mínimo* -$${premio_minimo}
*HORARIO DE PAGOS*: ${first_hour}AM A ${last_hour}
`
}


export const copylink = `Somos _cas.platinium ${link}`


export const procesando_premio = () => {
    return `Ya recibimos ${randomizer(['tu', 'la'])} información y estamos procesando tu premio. En breve te enviaremos el comprobante, muchas gracias!`
}


export const bienvenidaSinNombre = () => {

    return `👋 *Somos cas.platinium* 
${link}

Te creamos ${randomizer(['tu', 'una'])} cuenta gratuita, y te cargamos la cantidad de fichas que desees, a través de transferencia y se acreditan al instante.  

*Carga mínima* -$${carga_minima}
*Retiró mínimo* -$${premio_minimo}
*HORARIO DE PAGOS*: ${first_hour}AM A ${last_hour}

*TE PIDO NOMBRE Y APELLIDO PARA CREAR TU CUENTA*
`
}


export const infoBienvenida = (data) => {
    return `Te dejo tu usuario para entrar en ${link}
Como bienvenida tenes el +10% de bonificación🍀 

*CBU PARA CARGA DE FICHAS*
(consultar siempre antes de ${randomizer(['realizar una carga', 'cargar', 'transferir'])})

${data?.CBU}
Titular: ${data?.titular}
*Indicar nombre de usuario y remitente de la transferencia*
*Adjuntar comprobante*

*CBU vigente hasta las ${data?.hour_limit}hs*`
}


export const infoCbu = (data) => {
    return `${data?.CBU}
${randomizer(['Titular:', 'Titular', 'A nombre de'])} ${data?.titular}
*Aclarar*
*- Nombre de usuario*
*- Nombre y apellido del remitente de la transferencia*

*CBU vigente hasta las ${data?.hour_limit}hs*`
}

export const infoPremios = () => {
    return `Felicitaciones!!!☘️
Para retirar tu premio:
- Nombre de *USUARIO*
- Adjuntar *cbu* (por escrito)
- Especificar *MONTO* a retirar 💸
${randomizer(['Pronto', 'En breve', 'En unos minutos'])} será enviado🥂
`
}

export const premio_pagado = () => {
    return `${randomizer(['Muy contenta', 'Encantada'])} de poder pagarte el premio 🙌🏼 Felicitaciones! Queria comentarte que si estas conforme con nuestro trabajo podes recomendarnos con tus amigos/as y si me cargan fichas, te regalo el 10% en tu próxima carga 🙌🏻🎁🎰
Te dejo el link para que lo reenvíes: 
${linkReferido2}
`
}


export const fichas_standar = `FICHAS CARGADAS!!
GRACIAS POR CONFIAR🫶🏻🥳🥳🥳
`

export const recomendado = `Me acaban de hablar de tu parte! Gracias por recomendarme🫶🏻❤️‍🔥🙌🏽
Tenes un 10% de bonificación en tu proxima carga🎉🎊`


export const minima = `*Carga mínima* -$${carga_minima}
*Retiró mínimo* -$${premio_minimo}
*HORARIO DE PAGOS*: ${first_hour}AM A ${last_hour}`


export const premio_tarde = `Los pagos son de ${first_hour}am a ${last_hour}⏱️
Porfavor le pedimos a partir de ese horario especificar monto y cbu.
Buenos noches 🥰
`

export const cbu_anterior = `Te pido unos minutos ya que mandaste al cbu anterior y tengo que consultar si impacto la tranferencia, gracias por su paciencia! ❤️( siempre consultar cbu antes de transferir porfavor )`


export const publicidad = `Buenas noches! En este momento tenemos una publicidad activa por lo tanto tenemos más demanda de mensajes de lo normal
Le pedimos disculpas si hay demoras es por este motivo,ya estamos con usted🙏🏻
`

export const fichas_bajadas = () => {
    return `${randomizer(['Para', 'Por'])} su tranquilidad sus fichas están bajadas! En breve enviaremos su premio
Felicitaciones☘️🥰`
}

export const referido = () => {
    return `Si estas conforme con nuestro trabajo podes recomendarnos con tus amigos/as y si me cargan fichas, te regalo el 10% en tu próxima carga 🙌🏻🎁🎰 
Te dejo el link para que lo reenvíes: 
${linkReferido2}`
}
