import React, { useEffect, useState } from 'react'
import { AiFillLayout, AiFillWarning } from "react-icons/ai";
import { Card } from 'react-bootstrap';
import '../Settings.css'
import { updateAdminInfo } from '../../../../API/API';
import Swal from 'sweetalert2'

export const PanelConfig = ({ adminData }) => {
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [errors, setErrors] = useState('');

    const [formData, setFormData] = useState({
        nickname: '',
        panel_password: '',
        newPanelPassword: '',
        panel_password_repeat: '',
    });


    useEffect(() => {
        if (adminData) {
            setFormData({
                ...formData,
                nickname: adminData.nickname,
            })
        }

    }, [adminData])

    const handleCancelChangePw = () => {
        setShowChangePassword(false);
        setFormData({ ...formData, password: '', newPassword: '', password_repeat: '' })
    }


    const handleInputValues = (e) => {
        setErrors('')
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const submitForm = async () => {
        const payload = {
            nickname: formData.nickname,
        }

        if (formData.newPanelPassword) {
            if (formData.panel_password_repeat !== formData.newPanelPassword) {
                setErrors('Las contraseñas no coinciden')
                return;
            }
            payload.newPanelPassword = formData.newPanelPassword
            payload.panel_password = formData.panel_password
        }

        let apiResponse = await updateAdminInfo(payload);

        if (apiResponse?.response?.data?.msg === 'Bad panel password') {
            setErrors('Contraseña del panel actual incorrecta.')
            return;
        };

        if (apiResponse?.response?.status === 400) {
            setErrors('Ha habido un error. Intenta de nuevo')
            return;
        };


        Swal.fire({ text: `Actualizado correctamente` });

        setFormData({
            ...formData,
            panel_password: '',
            panel_password_repeat: '',
            newPanelPassword: '',
        })

        setShowChangePassword(false);
    }

    return (
        <Card className='bg-transparent'>
            <Card.Body className='settingsCard'>
                <Card.Title className='d-flex align-items-center justify-content-center'>
                    <AiFillLayout></AiFillLayout>
                    <span className='mx-2'>Configuración del PANEL</span>
                </Card.Title>
                <span className='text-secondary mx-2' style={{ fontSize: '12px' }}>Aquí configurarás nombre de usuario y contraseña utilizada para acceder al PANEL.</span>
                <label className='d-flex justify-content-start mt-3'>Nombre panel</label>
                <input name='nickname' onChange={handleInputValues} className='form-control customDarkInput rounded-md text-white' value={formData.nickname}></input>
                <br />

                {showChangePassword &&
                    <>
                        < label className='d-flex justify-content-start align-items-center'>Contraseña del panel actual</label>
                        <input autoComplete="off" name='panel_password' onChange={handleInputValues} type='password' className='form-control customDarkInput rounded-md text-white' value={formData.panel_password}></input>
                        <br />
                        <label className='d-flex justify-content-start align-items-center'>Nueva contraseña panel</label>
                        <input autoComplete="off" name='newPanelPassword' onChange={handleInputValues} type='password' className='form-control customDarkInput rounded-md text-white' value={formData.newPanelPassword}></input>
                        <br />
                        <label className='d-flex justify-content-start'>Repetir nueva contraseña panel</label>
                        <input autoComplete="off" name='panel_password_repeat' onChange={handleInputValues} type='password' className='form-control customDarkInput rounded-md text-white' value={formData.panel_password_repeat}></input>
                    </>

                }

                <span className='text-danger'><small>{errors && errors}</small></span>

                <div className='my-5 w-75 pb-4 m-auto'>
                    <AiFillWarning size={35} className="text-green"></AiFillWarning >
                    <div style={{ fontSize: '14px' }}>IMPORTANTE:</div>
                    <span className='text-white mx-2' style={{ fontSize: '13px' }}>El nombre y la contraseña deben ser exactamente las mismas (verifica mínusculas y mayúsculas) que se utilizan para entrar al panel principal, de otra forma el bot NO PODRÁ acceder a él, y no habrá ninguna acción disponible.</span>
                </div>



                <div className='d-flex justify-content-between mt-3'>
                    {!showChangePassword && <button className='btn btn-green-outline text-white' onClick={() => setShowChangePassword(true)}>Cambiar contraseña panel</button>}
                    {showChangePassword && <button className='btn btn-green-outline text-white' onClick={handleCancelChangePw}>Cancelar</button>}
                    <button className='btn btn-green' onClick={submitForm}>Guardar</button>
                </div>
            </Card.Body>
        </Card>
    )
}
