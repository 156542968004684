import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import { Link } from 'react-router-dom'
import { Col, Modal, Row } from 'react-bootstrap'
import { createButton, fetchButtonsAdmin, testAdminToken } from './API'
import { AiOutlineBell, AiOutlinePlusCircle, AiOutlineUser } from 'react-icons/ai'
import { TbGift, TbRosetteNumber1 } from 'react-icons/tb'
import { GrSubtractCircle } from 'react-icons/gr'
import { FiRefreshCcw, FiUnlock, FiUsers } from 'react-icons/fi'
import { MdNavigateNext, MdOutlineHistory, MdOutlineHistoryToggleOff, MdOutlineRemoveCircle, MdSettings, MdWarning } from 'react-icons/md'
import { BsFillGrid3X3GapFill, BsFillInfoCircleFill, BsPersonAdd, BsPlusSquare } from 'react-icons/bs'
import { NotificationsPanel } from './Notifications/NotificationsPanel'
import { BotUsersPanel } from './BotUsers/BotUsersPanel'
import { HistoryPanel } from './HistoryPanel/HistoryPanel'
import { postAdminAuth } from '../../API/API'
import Cookies from 'js-cookie';
import { BiDiamond, BiPlus, BiUser } from 'react-icons/bi'
import { Settings } from './Settings/Settings'
import Swal from 'sweetalert2'
import background from '../../assets/clientloginbg.png'
import { PaymentScaleModal } from './PaymentScaleModal/PaymentScaleModal'

export const Dashboard = () => {

    // const [password, setPassword] = useState([]);
    const [logged, setLogged] = useState(false)
    // const [isPressed, setIsPressed] = useState([]);
    // const [disableButtons, setDisableButtons] = useState(false)
    // const [connectionStatus, setConnectionStatus] = useState(false)
    const [buttons, setButtons] = useState([])

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showPaymentScaleModal, setShowPaymentScaleModal] = useState(false);

    const [bienvenidaButtons, setBienvenidaButtons] = useState([])
    const [infoButtons, setInfoButtons] = useState([])
    const [premiosButtons, setPremiosButtons] = useState([])
    const [fichasButtons, setFichasButtons] = useState([])
    const [extraButtons, setExtraButtons] = useState([])
    const [staticButtons, setStaticButtons] = useState([])

    const [currentTab, setCurrentTab] = useState(1)

    const [adminInfo, setAdminInfo] = useState({})
    // const adminName = process.env.REACT_APP_CAJERO
    const adminName = adminInfo.name
    const token = Cookies.get("Token")

    useEffect(() => {
        fetcherAndSetter()
        testToken()
    }, [token]);

    const testToken = async () => {
        let test = await testAdminToken();
        if (test?.data?.user?.platform === '') {
            document.documentElement.style.setProperty('--main-color', '#00C985');
        }

        if (test?.data?.user?.platform === 'pulpobet') {
            document.documentElement.style.setProperty('--main-color', '#6079ff');
        }
        if (test?.data?.user?.platform === 'megafaraon') {
            document.documentElement.style.setProperty('--main-color', '#6079ff');
        }
        if (test?.data?.status) {
            setAdminInfo(test.data.user)
            setLogged(true);
        }
    }

    const fetcherAndSetter = () => {
        fetchButtonsAdmin().then(res => {
            const bienvenidaButtons = [];
            const infoButtons = [];
            const premiosButtons = [];
            const fichasButtons = [];
            const extraButtons = [];
            const static_buttons = []

            res?.forEach(button => {
                if (button.category === 'bienvenida') {
                    bienvenidaButtons.push(button);
                } else if (button.category === 'info') {
                    infoButtons.push(button);
                } else if (button.category === 'premios') {
                    premiosButtons.push(button);
                } else if (button.category === 'fichas') {
                    fichasButtons.push(button)
                } else if (button.category === 'extra') {
                    extraButtons.push(button)
                } else if (button.category === 'static_button') {
                    static_buttons.push(button)
                }
            });

            setBienvenidaButtons(bienvenidaButtons);
            setInfoButtons(infoButtons);
            setPremiosButtons(premiosButtons);
            setFichasButtons(fichasButtons);
            setExtraButtons(extraButtons)
            setStaticButtons(static_buttons)
        });
    }

    // const handlePassword = (event) => {
    //     setIsPressed({ [event.target.id]: true });
    //     setTimeout(() => {
    //         setIsPressed({ [event.target.id]: false });
    //     }, 300);

    //     const number = event.target.id;

    //     if (password.length < 4) {
    //         setPassword([...password, number])
    //     } else {
    //         checkPassword()
    //         setPassword([])
    //     }
    // }

    // const key = process.env.REACT_APP_ADMINPW

    // const handleDisableButtons = () => {
    //     setDisableButtons(true);
    //     setTimeout(() => {
    //         setDisableButtons(false);
    //     }, 3000);
    // }

    // const checkPassword = () => {
    //     if (password.join("") === key) {
    //         setLogged(true)
    //         sessionStorage.setItem("token", password.join(""));
    //     } else {
    //         handleDisableButtons()
    //     }
    // }

    //Buttons tiene: nombre, id, category, messages

    const [newButton, setNewButton] = useState({
        name: '',
        category: 'bienvenida',
        messages: []
    })

    const handleNewButtonCreator = (event) => {
        setNewButton({ ...newButton, [event.target.name]: event.target.value })
    }

    const handleTextArea = (event) => {
        setNewButton({ ...newButton, messages: [event.target.value] })
    }

    const createNewButton = () => {
        let payload = {
            ...newButton,
            adminName: adminName
        }
        createButton(payload).then(res => {
            setButtons([...buttons, res])
            setShowCreateModal(false)
            fetcherAndSetter()
        }
        )
    }



    const iconsHandler = (button) => {
        if (button == "static_reset") { return <FiRefreshCcw size={25}></FiRefreshCcw> }
        if (button == "static_unblock") { return <FiUnlock size={25}></FiUnlock> }
        if (button == "static_bonus") { return <TbGift size={25} /> }
        if (button == "static_first") { return <TbRosetteNumber1 size={25}></TbRosetteNumber1> }
        if (button == "static_clone") { return <FiUsers size={25}></FiUsers> }
        if (button == "static_history") { return <MdOutlineHistoryToggleOff size={25}></MdOutlineHistoryToggleOff> }
        if (button == "static_subtract") { return <MdOutlineRemoveCircle size={25}></MdOutlineRemoveCircle > }
        if (button == "static_add") { return <AiOutlinePlusCircle size={25}></AiOutlinePlusCircle> }
        if (button == "static_newuser") { return <BsPersonAdd size={25}></BsPersonAdd> }

    }

    const [errorLogin, setErrorLogin] = useState(false);
    const [loginForm, setLoginForm] = useState({
        ownerName: '',
        password: ''
    })

    const handleLoginWithPassword = async (e) => {
        e.preventDefault();
        let auth = await postAdminAuth(loginForm)
        if (auth?.status === 200 && auth.data.token) {
            setLogged(true)
            // setUserLoggedName(loginForm.userName)
            if (auth?.data?.platform === 'pulpobet') {
                document.documentElement.style.setProperty('--main-color', '#6079ff');
            };

            if (auth?.data?.platform === 'megafaraon') {
                document.documentElement.style.setProperty('--main-color', '#6079ff');
            };

            if (auth?.data?.platform === '') {
                document.documentElement.style.setProperty('--main-color', '#00C985');
            }

            Cookies.set('Token', auth.data.token)
            sessionStorage.setItem('ownerName', loginForm.ownerName)
        } else {
            setErrorLogin(true)
        }
    }

    const handleLoginForm = (e) => {
        setErrorLogin(false)
        setLoginForm({
            ...loginForm,
            [e.target.name]: e.target.value
        })
    }

    const handleLogout = () => {
        Cookies.remove('Token');
        setLogged(false)
    }


    const checkTokenValidity = async () => {
        if (!logged) { return };

        let test = await testAdminToken();
        if (test?.data?.status) {
            setLogged(true);
        } else {
            Swal.fire({ icon: 'warning', text: 'Esta sesión ha caducado. Ingresa de nuevo para seguir operando.' });
            setLogged(false);
            setLoginForm({
                ownerName: '',
                password: ''
            })
            Cookies.remove("Token")
            localStorage.removeItem('loggedUser')
            localStorage.removeItem('userLocalPw')
        }
    };

    useEffect(() => {
        checkTokenValidity();
        const intervalId = setInterval(() => {
            checkTokenValidity();
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const getButtonName = (name) => {
        if (name === 'static_unblock') return 'Desbloquear'
        if (name === 'static_add') return 'Cargar fichas'
        if (name === 'static_bonus') return 'Cargar fichas con promoción'
        if (name === 'static_subtract') return 'Bajar fichas'
        if (name === 'static_newuser') return 'Crear usuario'
        if (name === 'static_reset') return 'Restablecer usuario'

        return name;
    }
    return (
        <>
            {showPaymentScaleModal &&
                <PaymentScaleModal setShowPaymentScaleModal={setShowPaymentScaleModal} showPaymentScaleModal={showPaymentScaleModal} />}

            <Modal size='lg' onHide={() => setShowCreateModal(false)} show={showCreateModal}>
                <Modal.Header>
                    <Modal.Title>Crear boton</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <small className='text-info'>Nombre:</small>
                    <input className='form-control mb-2 w-50 customDarkInput' placeholder='Nombre del boton' name='name' onChange={handleNewButtonCreator}></input>
                    <small className='text-info'>Categoria:</small>
                    <select className='form-control w-50 mb-4 customDarkInput' name='category' onChange={handleNewButtonCreator}>
                        <option value='bienvenida'>Bienvenida</option>
                        <option value='info'>Info</option>
                        <option value='premios'>Premios</option>
                        <option value='fichas'>Fichas</option>
                        <option value='extra'>Extra</option>
                    </select>
                    <textarea onChange={handleTextArea} className='form-control mt-2 customDarkInput' placeholder='Mensaje' style={{ minHeight: '250px' }}></textarea>
                    <hr></hr>
                    <small>
                        <small className='text-info'><b>Para agregar una variable, escribela entre doble guion bajo. Ejemplo: __CBU__</b></small>

                        <p>
                            Variables disponibles:

                            <ul className='list-unstyled mt-2'>
                                <li>
                                    <b>__CBU__</b> // CBU del día.
                                </li>
                                <li>
                                    <b>__TITULAR__</b> // Titula del CBU del día.
                                </li>
                                <li>
                                    <b>__ALIAS__</b> // Alias del CBU del día.
                                </li>
                                <li>
                                    <b>__HORA__</b> // Hora límite.
                                </li>
                                {/* <li>
                                    <b>__LINK__</b> // Link de la página.
                                </li> */}
                            </ul>

                        </p>
                    </small>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-green-outline' onClick={() => setShowCreateModal(false)}>Cancelar</button>
                    <button className='btn btn-green' onClick={createNewButton}>Crear</button>
                </Modal.Footer>
            </Modal>

            <div className={logged ? 'DashboardContainer' : 'DashboardContainerNotLogged'}>
                {!logged && <div className="position-absolute w-100" style={{ right: '0px', left: '0px', width: '100%' }}>
                    <img className="w-100" src={background}></img>
                </div>}
                <div className="ContainerGral">
                    {logged ?
                        (
                            <div className='m-3'>

                                <div className='m-auto ' style={{ minWidth: '450px' }}>
                                    <div className='d-flex justify-content-between w-100 gap-5 align-items-center mb-4 w-50 m-auto rounded py-1'>

                                        <div className="darkBackground d-flex p-1 d-flex justify-content-end px-2 align-items-center primary-green">
                                            <span className=' d-flex align-items-center text-center' style={{ fontSize: '14px' }}>
                                                <BiUser className='mx-1 text-white greem-hover' size={16}></BiUser> {adminInfo?.name}
                                            </span>
                                        </div>

                                        <div className="darkBackground d-flex p-1 d-flex justify-content-end">
                                            <div className='d-flex align-items-center btn py-0 greem-hover' style={{ fontSize: '12px' }} onClick={handleLogout}>
                                                Cerrar sesión <MdNavigateNext className='mx-2 text-white  greem-hover' size={16}></MdNavigateNext>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border border-primary-green py-1 rounded mt-2">
                                        Panel Administrador
                                    </div>

                                    <div className='my-4 d-flex justify-content-center align-items-center mx-auto'>
                                        <Col onClick={() => setCurrentTab(1)} className={`p-2 mx-1 rounded ${currentTab === 1 ? 'handlerButtonAdminSelected' : 'handlerButton'}`}>
                                            <BsFillGrid3X3GapFill size={20}></BsFillGrid3X3GapFill>
                                            <Row className='d-flex justify-content-center'><span className='text-center'>Botones</span></Row>
                                        </Col>
                                        <Col onClick={() => setCurrentTab(2)} className={`p-2 mx-1 rounded ${currentTab === 2 ? 'handlerButtonAdminSelected' : 'handlerButton'}`}>
                                            <AiOutlineBell size={20}></AiOutlineBell>
                                            <Row className='d-flex justify-content-center'><span className='text-center'>Notificaciones</span></Row>
                                        </Col>
                                        <Col onClick={() => setCurrentTab(3)} className={`p-2 mx-1 rounded ${currentTab === 3 ? 'handlerButtonAdminSelected' : 'handlerButton'}`}>
                                            <AiOutlineUser size={20}></AiOutlineUser>
                                            <Row className='d-flex justify-content-center'><span className='text-center'>Usuarios</span></Row>
                                        </Col>
                                        <Col onClick={() => setCurrentTab(4)} className={`p-2 mx-1 rounded ${currentTab === 4 ? 'handlerButtonAdminSelected' : 'handlerButton'}`}>
                                            <MdOutlineHistory size={20}></MdOutlineHistory>
                                            <Row className='d-flex justify-content-center'><span className='text-center'>Historial</span></Row>
                                        </Col>
                                        <Col onClick={() => setCurrentTab(5)} className={`p-2 mx-1 rounded ${currentTab === 5 ? 'handlerButtonAdminSelected' : 'handlerButton'}`}>
                                            <MdSettings size={20}></MdSettings >
                                            <Row className='d-flex justify-content-center'><span className='text-center'>Cuenta</span></Row>
                                        </Col>
                                    </div>

                                </div>

                                <div className="" style={{ minWidth: '950px' }}>

                                    {currentTab == 1 &&
                                        <>
                                            <div className='d-flex justify-content-center'>
                                                <div className='text-center d-flex justify-content-center mt-3'>

                                                    <div className='w-100'>
                                                        {bienvenidaButtons.length > 0 && <div className='mt-2 darkBackground px-5 py-2'>
                                                            <h5 className='text-secondary'>
                                                                "Bienvenida"
                                                            </h5>
                                                            {bienvenidaButtons?.map((button, index) => {
                                                                return (
                                                                    <Link to={`/admin/${button.id}`}>
                                                                        <button
                                                                            key={index}
                                                                            title={button.Messages[0]?.text}
                                                                            className="btn btn-customdark my-0 py-0 m-1"
                                                                            type="submit"
                                                                        >{button.name}
                                                                        </button>
                                                                    </Link>
                                                                )
                                                            })}
                                                        </div>
                                                        }
                                                        {infoButtons.length > 0 && <div className='mt-2 darkBackground px-5 py-2'>
                                                            <h5 className='text-secondary'>
                                                                "Info"
                                                            </h5>
                                                            {infoButtons?.map((button, index) => {
                                                                return (
                                                                    <Link to={`/admin/${button.id}`}>
                                                                        <button
                                                                            key={index}
                                                                            title={button.Messages[0]?.text}
                                                                            className="btn btn-customdark my-0 py-0 m-1"
                                                                            type="submit">{button.name}
                                                                        </button>
                                                                    </Link>
                                                                )
                                                            })}
                                                        </div>}

                                                        {premiosButtons.length > 0 && <div className='mt-2 darkBackground px-5 py-2'>
                                                            <h5 className='text-secondary'>
                                                                "Premios"
                                                            </h5>
                                                            {premiosButtons?.map((button, index) => {
                                                                return (
                                                                    <Link to={`/admin/${button.id}`}>
                                                                        <button
                                                                            key={index}
                                                                            title={button.Messages[0]?.text}
                                                                            className="btn btn-customdark my-0 py-0 m-1"
                                                                            type="submit">{button.name}
                                                                        </button>
                                                                    </Link>
                                                                )
                                                            })}
                                                        </div>
                                                        }

                                                        {fichasButtons.length > 0 && <div className='mt-2 darkBackground px-5 py-2'>
                                                            <h5 className='text-secondary'>
                                                                "Fichas"
                                                            </h5>
                                                            {fichasButtons?.map((button, index) => {
                                                                return (
                                                                    <Link to={`/admin/${button.id}`}>
                                                                        <button
                                                                            key={index}
                                                                            title={button.Messages[0]?.text}
                                                                            className="btn btn-customdark my-0 py-0 m-1"
                                                                            type="submit">{button.name}
                                                                        </button>
                                                                    </Link>
                                                                )
                                                            })}
                                                        </div>
                                                        }

                                                        {extraButtons.length > 0 && <div className='mt-2 darkBackground px-5 py-2'>
                                                            <h5 className='text-secondary'>
                                                                "Extra"
                                                            </h5>
                                                            {extraButtons?.map((button, index) => {
                                                                return (
                                                                    <Link to={`/admin/${button.id}`}>
                                                                        <button
                                                                            key={index}
                                                                            title={button.Messages[0]?.text}
                                                                            className="btn btn-customdark my-0 py-0 m-1"
                                                                            type="submit">{button.name}
                                                                        </button>
                                                                    </Link>
                                                                )
                                                            })}
                                                        </div>
                                                        }

                                                        <div className='mt-2 darkBackground px-5 py-3'>
                                                            <button className="btn btn-outline-secondary my-0 py-1 mx-1" onClick={() => setShowPaymentScaleModal(true)}>Escala de pagos</button>
                                                        </div>

                                                        <div className="d-flex align-items-center justify-content-center mt-3">
                                                            <BsFillInfoCircleFill className="text-secondary"></BsFillInfoCircleFill>
                                                            <div className="mx-1">
                                                                <small className='text-secondary'>Administra los nombres de cada botón y sus respectivos mensajes. </small>
                                                                <small className='text-secondary'>Para customizar un botón, dale click.</small>
                                                            </div>
                                                        </div>

                                                        <div className='mt-2 px-5 py-2'>
                                                            {staticButtons?.map((button, index) => {
                                                                return (
                                                                    <Link to={`/admin/${button.id}`}>
                                                                        {button.name !== 'static_first' && button.name !== 'static_history' && <button
                                                                            key={index}
                                                                            title={getButtonName(button.name)}
                                                                            className="btn btn-outline-secondary text-white border-0 my-0 mx-2 py-1 px-3 m-1 handlerButton rounded-md pt-2"
                                                                            type="submit">
                                                                            {iconsHandler(button.name)}
                                                                        </button>}
                                                                    </Link>
                                                                )
                                                            })}
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-5'>
                                                <button className='btn btn-green py-0' onClick={() => setShowCreateModal(true)}>
                                                    <span className="d-flex align-items-center">
                                                        <BsPlusSquare className="mr-2"></BsPlusSquare>
                                                        Crear botón
                                                    </span>
                                                </button>

                                            </div>

                                        </>
                                    }



                                    {currentTab == 2 &&
                                        <NotificationsPanel></NotificationsPanel>
                                    }


                                    {currentTab == 3 &&
                                        <BotUsersPanel></BotUsersPanel>
                                    }

                                    {currentTab == 4 &&
                                        <HistoryPanel></HistoryPanel>
                                    }

                                    {currentTab == 5 &&
                                        <Settings></Settings>
                                    }

                                </div>
                            </div>

                        )
                        :
                        (
                            <div className='userLoginBox mx-2 position-relative pt-5 mt-5 ' style={{ minWidth: '600px' }}>
                                <div className='mb-3' style={{ color: "var(--main-color)" }}>
                                    <BiDiamond size={50}></BiDiamond>
                                </div>

                                <div>
                                    <span style={{ fontSize: '13px' }}>Para iniciar sesión, ADMIN introducir administrador y contraseña</span>
                                    <form onSubmit={handleLoginWithPassword}>

                                        <div className="input-group-sm mb-2 mt-3">
                                            <small className="d-flex justify-content-start">Nombre Administrador</small>
                                            <input onChange={handleLoginForm} name='ownerName' value={loginForm.ownerName} type="text" className="customDarkInputLogin loginInput text-black form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder='Escribir administrador' />
                                        </div>

                                        <div className="input-group-sm mb-2">
                                            <small className="d-flex justify-content-start">Contraseña</small>
                                            <input onChange={handleLoginForm} name='password' value={loginForm.password} type="password" className="customDarkInputLogin loginInput text-black form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder='Escribir usuario' />
                                        </div>

                                        {errorLogin &&
                                            <div>
                                                <small className='text-danger mt-3'><b>Credenciales inválidas. Verifica los datos y vuelve a intentarlo.</b></small>
                                            </div>
                                        }
                                        <div>
                                            <button type='submit' disabled={!loginForm.ownerName || !loginForm.password} className='btnCustomLogin mt-2 w-100 py-1 border-0' style={{ fontSize: '14px' }}>
                                                Iniciar sesión
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        )}
                </div>
            </div >


        </>
    )
}
