import React, { useEffect, useRef } from 'react'
import axios from 'axios'
import { useState } from 'react';
import Swal from 'sweetalert2'

import { useSearchParams } from 'react-router-dom';
import './GameHistory.css'
import { Link } from 'react-router-dom';
import { getApiConfig } from '../../API/getConfig';

export const GameHistory = () => {
    const [gameHistory, setGameHistory] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [userName, setUserName] = useState('')
    const [loading, setLoading] = useState(false)
    const [noMoreRecords, setNoMoreRecords] = useState(false)
    const [searchParams] = useSearchParams();

    // const adminName = process.env.REACT_APP_CAJERO
    const [adminName, setAdminName] = useState(sessionStorage.getItem('ownerName'));

    useEffect(() => {
        const userName = searchParams.get('user');
        if (userName) {
            setUserName(userName)
            getUserGameHistory(userName)
        }
    }, [])

    const getUserGameHistory = async (name, newPage) => {
        // if (userName.length > 16 || userName.length < 3) { handleClose(false) }
        if (name.length > 16) { Swal.fire('El usuario no puede tener más de 16 letras. Verifica que esté bien.'); return }
        if (name.length < 3) { Swal.fire({ text: 'Ingresa al menos tres letras.' }); return }

        if (loading) { return }

        let page = newPage ? newPage : currentPage
        let history = []

        setLoading(true)
        const config = getApiConfig()
        try {
            await axios.post(`/gameHistory`, { name: name, adminName: adminName, page: page }, config)
                .then((result) => {
                    history = result.data
                    setLoading(false)
                    if (typeof result.data == 'string' && result?.data?.includes('ERROR')) Swal.fire({ text: result.data });
                    if (result?.data?.recordsTotal > 0 && result?.data?.length == 0) {
                        setNoMoreRecords(true)
                    } else {
                        setNoMoreRecords(false)
                        setGameHistory(history)
                    }
                })

        } catch (error) {
            console.log(error)
            Swal.fire({ text: `Ha habido un error. Contacta al administrador.` });
            setLoading(false)

        }

        // if (typeof history !== 'string') handleUserChargesGameHistory(history)
        setLoading(false)

    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        getUserGameHistory(userName, newPage);
    };

    const resetNavigation = () => {
        setCurrentPage(1);
        getUserGameHistory(userName, 1);
    }

    return (
        <div className='DashboardContainer' >
            <div className='gamehistorycontainer'>

                <div className="text-xs" style={{ minWidth: '1500px' }} role="document">

                    <div className=" text-white">
                        <div className="modal-header d-flex justify-content-between">
                            <Link to={'/'}>
                                <div className='d-flex align-items-center mx-1 text-primary' style={{ fontSize: '15px;', cursor: 'pointer' }}>
                                    <div className='mx-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-up-left-circle" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-5.904 2.803a.5.5 0 1 0 .707-.707L6.707 6h2.768a.5.5 0 1 0 0-1H5.5a.5.5 0 0 0-.5.5v3.975a.5.5 0 0 0 1 0V6.707z" />
                                        </svg>
                                    </div>
                                    Volver
                                </div>
                            </Link>

                            <h5 className="modal-title text-center d-flex justify-content-center">Historial de Juegos de {userName}</h5>

                        </div>
                        {gameHistory?.data?.length > 0 ?

                            <div className="modal-body" style={{ fontSize: '12px' }}>



                                <p>- Historial últimos 90 días:</p>
                                <div style={{ overflowX: 'auto' }}> {/* Contenedor para el desplazamiento horizontal */}

                                    <div className="d-flex justify-content-center mb-3 ">
                                        {/* Botones de paginación */}
                                        <button className='p-0 px-4 mx-2 text-start btn btn-dark' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>Anterior</button>
                                        <button disabled={noMoreRecords || loading} className='p-0 px-4 mx-2 text-start  btn btn-dark' onClick={() => handlePageChange(currentPage + 1)}>Siguiente</button>
                                    </div>

                                    {noMoreRecords ?
                                        <>
                                            <div>No hay más historial para este usuario</div>
                                        </>
                                        :
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Proveedor</th>
                                                    <th>Operacion</th>
                                                    <th>Importe</th>
                                                    <th>Saldo Anterior</th>
                                                    <th>Saldo Posterior</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {gameHistory?.data?.map((entry, index) => (
                                                    <tr key={index}>
                                                        <td>{entry[0]}</td>
                                                        <td>{entry[1]}</td>
                                                        <td>{entry[3]}</td>
                                                        <td>{entry[4]}</td>
                                                        <td>{entry[5]}</td>
                                                        <td>{entry[6]}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>}
                                </div>
                                <div className="d-flex justify-content-center mb-3 ">
                                    {/* Botones de paginación */}
                                    <button className='p-0 px-4 mx-2 text-start btn btn-dark' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>Anterior</button>
                                    <button disabled={noMoreRecords || loading} className='p-0 px-4 mx-2 text-start  btn btn-dark' onClick={() => handlePageChange(currentPage + 1)}>Siguiente</button>
                                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button> */}
                                </div>

                            </div>

                            :

                            !loading ?
                                <div className='text-white py-5'>
                                    No hay más historial para el usuario .
                                    <div className='mt-3'>
                                        <button onClick={resetNavigation} className='p-0 px-4 mx-2 text-start btn btn-dark'>Buscar de nuevo</button>
                                    </div>
                                </div>
                                :
                                <div className='text-small my-5 py-5'>
                                    Cargando..
                                </div>

                        }


                    </div>


                </div>
            </div>
        </div>
    )
}
