import React from 'react'
import siete from '../../assets/77.png'
// import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
    // const navigate = useNavigate();

    return (
        <div className='App'>
            <div className='mt-5 userLoginBox position-relative'>
                <img alt='logo' src={siete}></img>
            </div>
        </div>
    )
}
