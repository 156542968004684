import React, { useEffect, useRef } from 'react'
import './NewUser.css';
import axios from 'axios'
import { useState } from 'react';
import Swal from 'sweetalert2'
import { getApiConfig } from '../../../API/getConfig';

const GameHistoryModal = ({ historyArray, userName, showModal, handleClose, form, setLoading, adminName, loading }) => {

    const [gameHistory, setGameHistory] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        getUserGameHistory()
    }, [])

    const getUserGameHistory = async () => {
        if (form.name.length > 16 || form.name.length < 3) { handleClose(false) }
        if (form.name.length > 16) { Swal.fire('El usuario no puede tener más de 16 letras. Verifica que esté bien.'); return }
        if (form.name.length < 3) { Swal.fire({ text: 'Ingresa al menos tres letras.' }); return }

        if (!loading) { return }

        let history = []
        setLoading(false)

        try {
            let config = getApiConfig()
            await axios.post(`/gameHistory`, { name: form.name, adminName: adminName, page: currentPage }, config)
                .then((result) => {
                    console.log(result)
                    history = result.data
                    setLoading(true)
                    if (typeof result.data == 'string' && result?.data?.includes('ERROR')) Swal.fire({ text: result.data });
                })

        } catch (error) {
            console.log(error)
            Swal.fire({ text: `Ha habido un error. Contacta al administrador.` });
            setLoading(true)

        }

        console.log(history)
        setGameHistory(history)
        // if (typeof history !== 'string') handleUserChargesGameHistory(history)
        setLoading(true)

    }


    return (
        <div className={`modal-dialog ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ zIndex: '99999999' }}>
            <div className="modal-dialog" style={{ minWidth: '1500px' }} role="document">
                <div className="modal-body text-xs bg-secondary text-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Historial de Juegos de {userName}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>- Historial últimos 90 días:</p>
                        <div style={{ overflowX: 'auto' }}> {/* Contenedor para el desplazamiento horizontal */}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Proveedor</th>
                                        <th>Operacion</th>
                                        <th>Importe</th>
                                        <th>Saldo Anterior</th>
                                        <th>Saldo Posterior</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gameHistory?.data?.map((entry, index) => (
                                        <tr key={index}>
                                            <td>{entry[0]}</td>
                                            <td>{entry[1]}</td>
                                            <td>{entry[2]}</td>
                                            <td>{entry[3]}</td>
                                            <td>{entry[4]}</td>
                                            <td>{entry[5]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GameHistoryModal;
